<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>International certificate shipment requests</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ International certificate shipment requests
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                    <v-btn x-large dark @click="viewDispatchedReports()">View dispatched certificates</v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <!--            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                      label="Category Name"
                      v-model="search.name"
                      outlined
                      dense
                      v-on:keyup.enter="searchShipmentCertificateRequest()"
                      @input="search.name = $event !== null ? $event : ''"
                      clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-btn @click.prevent="searchShipmentCertificateRequest()"
                      class="btn btn-primary text-white"
                      :isLoading="isLoading">
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>-->
            <div class="table-responsive">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table">
                <thead>
                <tr class="px-3">
                  <th class="px-3">Enrolment key	</th>
                  <th class="px-3">Exam name</th>
                  <th class="px-3">Request date</th>
                  <th class="px-3">Address</th>
                  <th class="px-3">Shipping method</th>
                  <th class="px-3">Paid amount</th>
                  <th class="px-3">Status</th>
                  <th class="px-3">Options</th>
                </tr>
                </thead>
                <template>
                  <tr v-if="shipment_certificate_request.length > 0" v-for="item in shipment_certificate_request">
                    <td class="px-2">
                      <a  class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{item.exam_key }}
                      </a>
                    </td>
                    <td class="px-2">
                      <a  class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{item.product_name }}
                      </a>
                    </td>
                    <td class="px-2">
                      {{item.order_date}}
                    </td>
                    <td class="px-2">
                      <p  class=" font-weight-bolder  mb-1" v-html="item.full_address_format">
                      </p>
                    </td>
                    
                    <td class="px-2">
                      <a  class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{item.shipment_method_text }}
                      </a>
                    </td>

                    <td class="px-2">
                      <span  class="subtitle-2">
                        {{item.currency}}{{item.amount_total }}
                      </span>
                    </td>

                    
                    <td class="px-2">
                         <span class="badge badge-warning">
                            {{ item.physical_certificate_status}}
                          </span>
                    </td>

                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item" v-if="item.is_printed">
                              <a @click="assignTrackingNumber(item)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="blue darken-2">fas fa-print</v-icon>
                                </span>
                                <span class="navi-text">Mark Dispatched</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="!item.is_printed">
                              <a @click="printShipmentCertificate(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="blue darken-2">fas fa-arrow-right</v-icon>
                                </span>
                                <span class="navi-text">Mark As Print</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="viewInvoice(item.cart_id,item.user_id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-file-invoice"></i>
                                </span>
                                <span class="navi-text">View Invoice</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="shipment_certificate_request.length == 0">
                    <td colspan="9" class="text-center">
                      <strong>No items added</strong>
                    </td>
                  </tr>
                </template>
              </table>
              <b-pagination
                  v-if="shipment_certificate_request.length > 0"
                  class="pull-right mt-7"
                  @input="getAllShipmentCertificateRequest"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <add-tracking ref="assign-tracking" @refresh="getAllShipmentCertificateRequest"></add-tracking>
    </div>
  </v-app>
</template>
<script>
import ShipmentCertificateRequestService from "@/services/shipment-certificate-request/ShipmentCertificateRequestService";
import AddTracking from "./widget/AddTracking";
import AccountholderService from "@/services/user/accountholder/AccountholderService";
const accountHolder=new AccountholderService();
const shipmentCertificateRequest=new ShipmentCertificateRequestService();
export default {
  components: {
    AddTracking,
  },
  data(){
    return{
      isLoading:false,
      search:{
        type:'pending',
      },
      shipment_certificate_request:[],
      total: null,
      perPage: null,
      page: 1,
    }
  },
  methods:{
    viewDispatchedReports() {
      this.$router.push({
        name: "shipment-certificate-request-dispatched",
      });
    },
    searchShipmentCertificateRequest(){
      this.getAllShipmentCertificateRequest();
    },
    getAllShipmentCertificateRequest(){
      shipmentCertificateRequest
          .paginate(this.search,this.page)
          .then(response => {
            this.shipment_certificate_request=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch((err) => {

          }).finally(() => {
              this.isLoading = false;
          });
    },
    assignTrackingNumber(item){
      this.$refs['assign-tracking'].addTrackingNumber(item);
    },
    printShipmentCertificate(itemId){
      this.$confirm({
        message: `Are you sure ? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            shipmentCertificateRequest
                .printShipmentCertificateRequest(itemId,null)
                .then((response) => {
                  this.getAllShipmentCertificateRequest();
                  this.$snotify.success("Shipment Certificate Request Successfully");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                })
          }
        },
      });
    },
    viewInvoice(cartId,userId) {
      accountHolder
          .viewInvoice(userId, cartId);
    },

  },
  mounted() {
    this.getAllShipmentCertificateRequest();
  }
}
</script>